import React from "react"

interface DateBadgeProps {
  date: string
}

export const DateBadge = ({ date }: DateBadgeProps) => {
  const dateJS = new Date(date)
  const longMonth = dateJS
    .toLocaleDateString("en-US", {
      month: "long",
      day: "numeric",
      year: "numeric",
    })

  return (
    <span className="fr-blog-post__published-date u-text--align-center">
      <time dateTime={date}>{longMonth}</time>
    </span>
  )
}
