import React from "react"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import "./breadcrumbs.module.scss"

type BreadcrumbsProps = {
  crumbs: Record<string, string>[]
  crumbLabel: string
  hiddenCrumbs?: string[]
}

export const Breadcrumbs = ({
  crumbs,
  crumbLabel,
  hiddenCrumbs,
}: BreadcrumbsProps) => {
  return (
    <section className="breadcrumbs">
      <div className="fr-container">
        <Breadcrumb
          crumbs={crumbs}
          crumbLabel={crumbLabel}
          crumbSeparator=" > "
          hiddenCrumbs={hiddenCrumbs}
        />
      </div>
    </section>
  )
}
