import React from "react"
import { Button } from "components/button"
import { BlogPostEdge } from "../BlogBanner/blog-banner-types"
import "./internal-pagination.module.scss"
import { useLayout } from "contexts/components/LayoutContext"
import { LeftChevron, RightChevron } from "assets/helper/icons"

interface InternalPaginationProps {
  prev?: BlogPostEdge["node"]
  next?: BlogPostEdge["node"]
  parentSlug: string
  isPrevNextEnabled?: boolean
}

export const InternalPagination = ({
  prev,
  next,
  parentSlug,
  isPrevNextEnabled = false,
}: InternalPaginationProps) => {
  const { linkedPagesUrlPathCodex } = useLayout()

  const formatParentSlugText = (slug: string) => {
    if (slug.endsWith("s")) {
      return slug
    }
    return `${slug}s`
  }

  const withoutNextAndPreviousClassName = isPrevNextEnabled
    ? ""
    : "fr-internal-pagination--no-prev-next-links"

  const previousSlug = prev?.elements.slug.value || ""
  const nextSlug = next?.elements.slug.value || ""

  return (
    <section className="fr-internal-pagination__container">
      <div
        className={`fr-container fr-internal-pagination ${withoutNextAndPreviousClassName}`}
      >
        {isPrevNextEnabled && (
          <Button
            variant="outline-secondary"
            to={previousSlug && `/${linkedPagesUrlPathCodex[previousSlug]}`}
            extraClassNames={`fr-internal-pagination__btn fr-internal-pagination__btn--previous ${
              !previousSlug ? "fr-internal-pagination__btn--disabled" : ""
            }`}
            disabled={!previousSlug}
          >
            <LeftChevron className="fr-internal-pagination__btn--icon" />
            <span className="fr-internal-pagination__btn--label">Previous</span>
          </Button>
        )}
        <Button
          variant="outline-secondary"
          to={`/${parentSlug}`}
          extraClassNames="fr-internal-pagination__btn fr-internal-pagination__btn--view-all"
        >
          <span className="fr-internal-pagination__btn--label">
            View All {formatParentSlugText(parentSlug)}
          </span>
        </Button>
        {isPrevNextEnabled && (
          <Button
            variant="outline-secondary"
            to={nextSlug && `/${linkedPagesUrlPathCodex[nextSlug]}`}
            extraClassNames={`fr-internal-pagination__btn fr-internal-pagination__btn--next ${
              !next ? "fr-internal-pagination__btn--disabled" : ""
            }`}
            disabled={!nextSlug}
          >
            <span className="fr-internal-pagination__btn--label">Next</span>
            <RightChevron className="fr-internal-pagination__btn--icon" />
          </Button>
        )}
      </div>
    </section>
  )
}
